import React, { useState, useEffect, useCallback } from "react";
import ReactPaginate from "react-paginate";
import DefaultLayout from "../components/layouts/DefaultLayout";
import { graphql } from "gatsby";
import PostCard from "../components/PostCard";
import Hero from "../components/StaticPage/Hero";
import Tabs from "../components/UI/Tabs";
import SEO from "../components/layouts/SEO";
import { IPost, ISiteMetaData } from "../types";

const TAGS = ["General", "News", "ClickHouse", "Tools", "Streaming", "Ensemble CI"];

interface IBlogProps {
  data: {
    meta: {
      siteMetadata: ISiteMetaData;
    };
    posts: {
      nodes: Array<{
        frontmatter: IPost;
        id: string;
        slug: string;
        excerpt: string;
      }>;
    };
  };
}

const Blog: React.FC<IBlogProps> = ({ data }) => {
  const PER_PAGE = 15;

  const meta = data.meta.siteMetadata;

  const originalBlogList = data.posts.nodes;
  const [blogList, setBlogList] = useState(originalBlogList);
  const [currentItems, setCurrentItems] = useState(originalBlogList);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  const [selected, setSelected] = useState(0);
  const tabs = [
    { id: 0, label: "All", count: originalBlogList.length },
    ...TAGS.map((label, id) => ({
      id: id + 1,
      label,
      count: originalBlogList.filter((blog) => {
        if (blog.frontmatter.tag) {
          if (blog.frontmatter.tag.split(",").indexOf(label) > -1) return blog;
        }
      }).length,
    })),
  ];

  const handlePageClick = (event: { selected: number }) => {
    const newOffset = (event.selected * PER_PAGE) % blogList.length;
    setItemOffset(newOffset);
  };

  useEffect(() => {
    const endOffset = itemOffset + PER_PAGE;

    setCurrentItems(blogList.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(blogList.length / PER_PAGE));
  }, [itemOffset, blogList]);

  const filterPosts = useCallback(
    (tag: string) => {
      if (tag === "All") {
        setBlogList(originalBlogList);
      } else {
        const filtered = originalBlogList.filter((blog) => {
          if (blog.frontmatter.tag) {
            if (blog.frontmatter.tag.split(",").indexOf(tag) > -1) return blog;
          }
        });
        setBlogList(filtered);
      }
    },
    [originalBlogList]
  );

  const handleSelect = (tabId: number) => {
    setSelected(tabId);
    filterPosts(tabs.find((tab) => tab.id === tabId)?.label ?? '');
    setItemOffset(0);
  };

  return (
    <DefaultLayout>
      <SEO
        title="Insights | Ensemble"
        url={`${meta.siteUrl}/blog`}
        desc={meta.description}
      />
      <Hero
        title='Insights'
        description='Latest Thinking From The Team'
        backgroundColor={["#40b7de", "#1976e0"]}
      />
      <div className="pt-10 pb-10 max-w-[1188px] px-6 mx-auto flex justify-center">
        <Tabs
          tabs={tabs}
          selected={selected}
          onSelect={handleSelect}
          className="!bg-transparent !p-0 !h-[46px]"
        />
      </div>
      <div className="max-w-[1188px] px-6 mx-auto">
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-y-10 lg:gap-y-[68px] gap-x-6 mb-2">
          {currentItems.map((blog, index) => (
            <PostCard
              post={blog}
              key={index}
              background_color={blog.frontmatter.background_color}
              noSpacing
            />
          ))}
        </div>
        <div className="my-10 lg:my-20">
          {tabs[selected].count > 15 && (
            <ReactPaginate
              breakLabel="..."
              onPageChange={handlePageClick}
              pageRangeDisplayed={10}
              pageCount={pageCount}
              renderOnZeroPageCount={null as any}
              containerClassName={"pagination font-poppins"}
              previousLinkClassName={"pagination-link pagination-link_previous"}
              nextLinkClassName={"pagination-link pagination-link_next"}
              disabledClassName={"pagination-link__disabled"}
              activeClassName={"pagination-link__active"}
              previousLabel={""}
              nextLabel={""}
            />
          )}
        </div>
      </div>
    </DefaultLayout>
  );
};

export const query = graphql`
  query {
    posts: allMdx(
      filter: { fields: { source: { eq: "blog" } } }
      sort: { fields: frontmatter___date_updated, order: DESC }
    ) {
      nodes {
        frontmatter {
          date_updated
          title
          tag
          excerpt
          featured_image {
            childImageSharp {
              gatsbyImageData(width: 1024, placeholder: NONE)
            }
          }
          background_color
        }
        id
        slug
        excerpt
      }
    }
    meta: site {
      siteMetadata {
        description
        title
        siteUrl
      }
    }
  }
`;

export default Blog;
