import classNames from "classnames";
import React from "react";

interface ITab {
  label: string;
  onClick: () => void;
  isActive: boolean;
  count?: number;
}

const Tab: React.FC<ITab> = ({ label, onClick, isActive, count }) => {
  return (
    <button
      onClick={onClick}
      className={classNames(
        "flex justify-center items-center gap-[9px] px-5 py-3 border rounded-lg border-[#524858] bg-brown-500 w-auto hover:border-white transition",
        {
          "gradient-purple-border px-[19px] py-[11px]": isActive,
        }
      )}
    >
      <span className="text-white text-sm font-semibold">{label}</span>
      {count && (
        <span
          className={classNames(
            "text-white flex items-center justify-center px-2 h-[22px] text-sm bg-grey-lite rounded-[50px]",
            {
              "!bg-gradient-to-r from-purple-500 to-purple-600": isActive,
            }
          )}
        >
          {count}
        </span>
      )}
    </button>
  );
};

export default Tab;
