import classNames from "classnames";
import React from "react";
import Tab from "./Tabs/Tab";

interface ITabsProps {
  selected: number;
  onSelect: (id: number) => void;
  className?: string;
  tabs: Array<{
    id: number;
    label: string;
    count?: number;
  }>;
}

const Tabs: React.FC<ITabsProps> = ({
  tabs,
  selected,
  onSelect,
  className,
}) => {
  return (
    <div
      className={classNames(
        "w-full rounded-lg lg:max-w-[1140px] h-[70px] flex lg:justify-center gap-4 px-5 py-3 mx-auto overflow-x-auto whitespace-nowrap",
        {
          [className ?? ""]: !!className,
        }
      )}
    >
      {tabs.map((tab) => (
        <Tab
          key={tab.id}
          onClick={onSelect.bind(null, tab.id)}
          isActive={selected === tab.id}
          count={tab?.count}
          label={tab.label}
        />
      ))}
    </div>
  );
};

export default Tabs;
