import classNames from "classnames";
import React from "react";

interface IHeroProps {
  title: string;
  description?: string;
  children?: React.ReactNode;
  image?: React.ReactNode;
  className?: string;
  backgroundColor?: string[];
  additionalBackgroundColor?: string,
}

const Hero: React.FC<IHeroProps> = ({
  title,
  description,
  children,
  className,
  backgroundColor,
  image,
  additionalBackgroundColor,
}) => {
  return (
    <section style={{backgroundColor: additionalBackgroundColor ? additionalBackgroundColor : ''}}>
      <div
        className={classNames(
          "lg:pt-[136px] lg:pb-[64px] pb-10 pt-[96px] px-6",
          {
            [className ?? ""]: !!className,
            "lg:pb-[188px]": !!image,
            "rounded-bl-[45px] rounded-br-[45px] xl:rounded-br-[100px] xl:rounded-bl-[100px]":
              !!backgroundColor,
          }
        )}
        style={{
          background: backgroundColor
            ? `linear-gradient(120deg, ${backgroundColor[0]} 0%, ${backgroundColor[1]} 100%)`
            : undefined,
        }}
      >
        <div className="flex justify-center max-w-[1140px] mx-auto w-full">
          <div className="space-y-2">
            <h1 className="lg:text-[34px] text-[34px] font-bold text-white text-center">
              {title}
            </h1>
            {!!description && (
              <p className="text-white text-lg font-semibold text-center">
                {description}
              </p>
            )}
            {children}
          </div>
        </div>
      </div>
      {image && (
        <div className="max-w-[1188px] w-full mx-auto relative lg:top-[-142px] px-6 pt-10 lg:py-0 lg:mb-[-142px]">
          {image}
        </div>
      )}
    </section>
  );
};

export default Hero;
