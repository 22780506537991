import React from "react";
import { Helmet } from "react-helmet";

interface ISEOProps {
  url: string;
  desc: string;
  title: string;
  ogImage?: string;
  article?: boolean;
}

const SEO: React.FC<ISEOProps> = ({ url, title, desc, ogImage, article }) => {
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="generator" content={desc} />
      <meta name="description" content={desc} />

      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content="@EnsembleData" />
      <meta name="twitter:creator" content="@EnsembleData" />
      {url && <meta name="twitter:url" content={url} />}
      <meta name="twitter:title" content={`${title}`} />
      <meta name="twitter:description" content={desc} />
      {ogImage && <meta name="twitter:image" content={ogImage} />}

      <meta property="og:type" content="article" />
      <meta property="og:title" content={`${title}`} />
      {url && <meta property="og:url" content={url} />}
      <meta property="og:description" content={desc} />
      <meta property="og:site_name" content="Ensemble" />
      <meta property="og:image" content={ogImage} />
      <meta property="og:image:width" content="1200" />
      <meta property="og:image:height" content="630" />
      
      {article && <meta name="author" content="Benjamin Wootton"/>}
    </Helmet>
  );
};

export default SEO;
